export default {
  data() {
    return {
      currency_list: [
        { txt: '美元USD', value: 'USD' },
        { txt: '人民币CNH', value: 'CNH' },
        { txt: '港币HKD', value: 'HKD' },
        { txt: '欧元EUR', value: 'EUR' },
        { txt: '英镑GBP', value: 'GBP' },
        { txt: '日元JPY', value: 'JPY' },
        { txt: '新加坡元SGD', value: 'SGD' },
        { txt: '澳元AUD', value: 'AUD' },
        { txt: '加拿大元CAD', value: 'CAD' },
        { txt: '纽元NZD', value: 'NZD' },
        { txt: '法郎CHF', value: 'CHF' },
      ],
    };
  },
};
